.source .source {
  margin-left: 24px;
  margin-bottom: 0;
}

.source:first-of-type {
  margin-top: 0 !important;
}

.MuiList-root > .MuiListItem-container:last-of-type > .MuiListItem-divider {
  border-bottom: none;
}

a {
  color: inherit;
  text-decoration: none;
}
